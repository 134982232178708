import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ThemeConsumer, ToggleSwitch} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const HandleOauthRedirectsToggle: React.FC = () => {
    const [isEnabled, setIsEnabled] = useState<boolean>(
        useManifestKey('applicationConfig').handleOauthRedirects ?? false,
    );
    const dispatch = useBrandDispatch();
    const {t} = useTranslation();

    const handleToggle = () => {
        setIsEnabled(enabled => {
            dispatch({
                type: 'SET_HANDLE_OAUTH_REDIRECTS',
                handleOauthRedirects: !enabled,
            });
            return !enabled;
        });
    };

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <ToggleSwitch
                    colorScheme={defaultColorScheme}
                    checked={isEnabled}
                    label={t(
                        'branding.handle-oauth-redirects',
                        'Handle OAuth 2.0 redirects',
                    )}
                    name="handleOauthRedirects"
                    onChange={handleToggle}
                />
            )}
        </ThemeConsumer>
    );
};
