import React from 'react';
import cx from 'classnames';

import {Button} from '../../elements/Button/Button';
import type {ButtonProps} from '../../elements/Button/Button';
import {Icon, IconTypes} from '../../elements/Icon/Icon';

import styles from './PreviewButton.module.scss';

export type Asset = {
    id: string;
    src?: string;
    color?: string;
};

export type ImageAsset = {
    id: string;
    src?: string;
};

export type ColorAsset = {
    id: string;
    color?: string;
};

const AssetPreview: React.FC<{assets?: Asset[]}> = ({assets}) => (
    <>
        {assets?.map(asset => (
            <figure
                key={asset.id}
                className={cx(styles.previewAsset)}
                style={{
                    backgroundImage: asset.src
                        ? `url('${asset.src}')`
                        : undefined,
                    backgroundColor: `${asset.color}`,
                }}
            >
                {asset.src ? (
                    <img
                        className={styles.previewAssetImg}
                        alt="A preview of the currently selected asset."
                    />
                ) : null}
            </figure>
        ))}
    </>
);

export const AssetPreviewButton: React.FC<
    React.PropsWithChildren<
        ButtonProps & {
            assets?: Asset[];
        }
    >
> = ({assets, className, children, ...props}) => (
    <Button
        variant="translucent"
        noHover
        className={cx(styles.button, className)}
        {...props}
    >
        <div className={styles.assetsWrapper}>
            <AssetPreview assets={assets} />
        </div>
        <div className={styles.overlay} />
        <div className={styles.iconsWrapper}>
            <Icon source={IconTypes.IconEdit} size="medium" />
            {children}
        </div>
    </Button>
);

export type AssetPreviewButtonProps = React.ComponentProps<
    typeof AssetPreviewButton
>;
