import React, {createContext, useContext, useReducer} from 'react';

import type {ColorScheme, OverlayOpacity} from '@pexip/components';

import {DEFAULT_MANIFEST} from '../constants';
import type {Manifest} from '../types';

type State = {
    manifest: Manifest;
    background?: Blob;
    logo?: Blob;
    jumbotron?: Blob;
};

export type ImageAsset = {name: string; blob: Blob};

export type Action =
    | {type: 'SET_APP_TITLE'; appTitle: string}
    | {type: 'SET_BRAND'; brand: State}
    | {type: 'SET_BRAND_NAME'; brandName: string}
    | {type: 'SET_BACKGROUND_COLOR'; backgroundColor: string}
    | {type: 'SET_BASE_COLOR'; baseColor: string}
    | {type: 'SET_OVERLAY'; overlay: ColorScheme}
    | {type: 'SET_OVERLAY_OPACITY'; overlayOpacity: OverlayOpacity}
    | {type: 'SET_COLOR_PALETTE'; colorPalette: string[]}
    | {type: 'SET_TERMS_AND_CONDITIONS'; termsAndConditions: string}
    | ({type: 'SET_BACKGROUND'} & ImageAsset)
    | ({type: 'SET_LOGO'} & ImageAsset)
    | ({type: 'SET_JUMBOTRON'} & ImageAsset)
    | {type: 'SET_DISCONNECT_DESTINATION'; disconnectDestination: string}
    | {type: 'SET_HANDLE_OAUTH_REDIRECTS'; handleOauthRedirects: boolean}
    | {type: 'SET_MIRROR_SELFVIEW'; mirrorSelfview: boolean};

export type ImageActionTypes = Extract<
    Action['type'],
    'SET_BACKGROUND' | 'SET_LOGO' | 'SET_JUMBOTRON'
>;

// Current implementation keeps all data structure in one context.
// Probably doesn't really matter as we keep all the updates in manifest obj
// which will rerender whole thing after we many any update to it, but
// alternatively we could generate manifest as a last step and then we could
// probably split this data into multiple contexts so they rerender things independently
export const BrandContext = createContext<
    [State, React.Dispatch<Action>] | undefined
>(undefined);

const brandReducer = (state: State, action: Action): State => {
    switch (action.type) {
        case 'SET_APP_TITLE':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    appTitle: action.appTitle,
                },
            };
        case 'SET_BRAND':
            return {
                ...state,
                ...action.brand,
            };
        case 'SET_BRAND_NAME':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    brandName: action.brandName,
                },
            };
        case 'SET_BACKGROUND_COLOR':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    backgroundColor: action.backgroundColor,
                },
            };
        case 'SET_BASE_COLOR':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    meta: {
                        ...state.manifest.meta,
                        baseColor: action.baseColor,
                    },
                },
            };
        case 'SET_OVERLAY':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    overlay: action.overlay,
                },
            };
        case 'SET_OVERLAY_OPACITY':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    overlayOpacity: action.overlayOpacity,
                },
            };
        case 'SET_COLOR_PALETTE':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    colorPalette: action.colorPalette,
                },
            };
        case 'SET_BACKGROUND':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    images: {
                        ...state.manifest.images,
                        background: action.name,
                    },
                },
                background: action.blob,
            };
        case 'SET_LOGO':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    images: {
                        ...state.manifest.images,
                        logo: action.name,
                    },
                },
                logo: action.blob,
            };
        case 'SET_JUMBOTRON':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    images: {
                        ...state.manifest.images,
                        jumbotron: action.name,
                    },
                },
                jumbotron: action.blob,
            };
        case 'SET_TERMS_AND_CONDITIONS':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    applicationConfig: {
                        ...state.manifest.applicationConfig,
                        termsAndConditions: {
                            ...state.manifest.applicationConfig
                                .termsAndConditions,
                            en: action.termsAndConditions,
                        },
                    },
                },
            };
        case 'SET_DISCONNECT_DESTINATION':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    applicationConfig: {
                        ...state.manifest.applicationConfig,
                        disconnectDestination: action.disconnectDestination,
                    },
                },
            };
        case 'SET_HANDLE_OAUTH_REDIRECTS':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    applicationConfig: {
                        ...state.manifest.applicationConfig,
                        handleOauthRedirects: action.handleOauthRedirects,
                    },
                },
            };
        case 'SET_MIRROR_SELFVIEW':
            return {
                ...state,
                manifest: {
                    ...state.manifest,
                    defaultUserConfig: {
                        ...state.manifest.defaultUserConfig,
                        mirrorSelfview: action.mirrorSelfview,
                    },
                },
            };
        default:
            throw new Error(`Unhandled action ${action}`);
    }
};

export const BrandProvider: React.FC<React.PropsWithChildren> = ({
    children,
}) => {
    const reducer = useReducer(brandReducer, {manifest: DEFAULT_MANIFEST});

    return (
        <BrandContext.Provider value={reducer}>
            {children}
        </BrandContext.Provider>
    );
};

export const useBrand = () => {
    const context = useContext(BrandContext);

    if (typeof context === 'undefined') {
        throw new Error('useBrand must be used within a BrandProvider');
    }

    return context;
};

export const useBrandState = () => {
    const [state] = useBrand();

    return state;
};

export const useBrandDispatch = () => {
    const [, dispatch] = useBrand();
    return dispatch;
};

export function useManifestKey<K extends keyof Manifest>(key: K) {
    const [{manifest}] = useBrand();
    return manifest[key];
}
