import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';

import {ThemeConsumer, ToggleSwitch} from '@pexip/components';

import {useBrandDispatch, useManifestKey} from '../contexts/Brand.context';

export const SelfviewMirrorToggle: React.FC = () => {
    const [isEnabled, setIsEnabled] = useState<boolean>(
        useManifestKey('defaultUserConfig').mirrorSelfview ?? true,
    );
    const dispatch = useBrandDispatch();
    const {t} = useTranslation();

    const handleToggle = () => {
        setIsEnabled(enabled => {
            dispatch({
                type: 'SET_MIRROR_SELFVIEW',
                mirrorSelfview: !enabled,
            });
            return !enabled;
        });
    };

    return (
        <ThemeConsumer>
            {({colorScheme: defaultColorScheme}) => (
                <ToggleSwitch
                    colorScheme={defaultColorScheme}
                    checked={isEnabled}
                    label={t('branding.selfview-mirror', 'Mirror selfview')}
                    name="allowSelfviewVideoFlip"
                    onChange={handleToggle}
                />
            )}
        </ThemeConsumer>
    );
};
